<template>
    <wit-modal
        v-model="showModal"
        title="Remove file"
        variant="danger"
        disableDBlock
        size="sm"
        customClass="wit-custom-query-storage-remove-modal"
        @input="emitInput"
    >
        <h2>
            Do you want to remove this file?
        </h2>
        <p>{{ filename }}</p>
        <div class="wit-custom-query-storage-remove-modal__btn-row-wrapper">
            <div class="wit-custom-query-storage-remove-modal__btn-row">
                <b-button variant="warning" size="sm" @click="yes">Yes</b-button>
                <b-button variant="secondary" size="sm" @click="close">No</b-button>
            </div>
        </div>
    </wit-modal>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        filename: {
            type: String,
            default: '',
        },
    },
    components: {
        WitModal: () => import('@/components/Modals/WitModal.vue'),
    },
    data() {
        return {
            showModal: false,
        }
    },
    watch: {
        value() {
            this.showModal = this.value
        },
    },
    created() {
        this.showModal = this.value
    },
    methods: {
        emitInput() {
            this.$emit('input', this.showModal)
        },
        yes() {
            this.$emit('remove')
        },
        close() {
            this.showModal = false
            this.emitInput()
        },
    },
}
</script>

<style lang="scss">
.wit-custom-query-storage-remove-modal {
    h2 {
        font-size: 1.2rem;
        text-align: center;
    }

    p {
        text-align: center;
    }

    .wit-custom-query-storage-remove-modal__btn-row-wrapper {
        .wit-custom-query-storage-remove-modal__btn-row {
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;

            button {
                margin: 0 5px;
            }
        }
    }
}
</style>
